<template>
  <div>
    <a-modal v-model="isShow" centered :mask-closable="false" title="VPDN IP地址">
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 关闭 </a-button>
      </template>

      <div>
        <a-form class="simiot-nested-search-form">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-item label="IP">
                <a-input v-model="ip" allow-clear />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item>
                <a-space>
                  <a-button class="search" @click="handleSearch"> 搜索 </a-button>
                  <a-button @click="handleReset"> 刷新 </a-button>
                </a-space>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <a-table
        size="middle"
        :columns="columns"
        :bordered="false"
        :data-source="ipSearch"
        :scroll="{ y: 200 }"
        :loading="loading"
        :pagination="false"
        row-key="ip"
        class="vpdn-ips-head"
        :row-class-name="rowBackGround"
      />

      <div class="margin-top-20">共 {{ ipSearch.length }} 条记录</div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'VpdnIps',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    ips: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      ip: '',
      ipSearch: [],
      data: [],
      loading: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    columns() {
      return [
        {
          title: 'IP地址',
          dataIndex: 'ip',
          key: 'ip'
        }
      ]
    }
  },
  created() {
    this.data = this.ips.map((e) => {
      return { ip: e }
    })
    this.ipSearch = this.data
  },
  methods: {
    rowBackGround(record, index) {
      return index % 2 === 0 ? 'ip-background no-border' : 'no-border'
    },

    handleCancel() {
      this.isShow = false
    },

    handleReset() {
      this.ip = ''
      this.loading = true
      this.ipSearch = this.data
        .filter((e) => {
          return e.ip.indexOf(this.ip) !== -1
        })

      this.loading = false
    },

    handleSearch() {
      this.loading = true
      this.ipSearch = this.data
        .filter((e) => {
          return e.ip.indexOf(this.ip) !== -1
        })

      this.loading = false
    }
  }
}
</script>
<style lang="less">
.vpdn-ips-head .ant-table-thead > tr > th {
  background: #fff;
  font-family: "PingFangSC-Semibold", "PingFang SC Semibold", "PingFang SC";
  font-weight: 650;
  padding-bottom: 5px;
  border-bottom: none !important;
}

.no-border td {
  border-bottom: none !important;
}

.ip-background {
  background: #dae1e7;
}

.search {
  background-color: #46a6ff;
  border-color: #46a6ff;
  color: #fff;
}

.search:hover, .search:focus {
  background-color: #6ebeff;
  border-color: #6ebeff;
  color: #fff;
}

.margin-top-20 {
  margin-top: 20px;
}
</style>
